@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&family=Work+Sans:wght@300;400;500;600&display=swap');

.home-slider {
    position: relative;
}

.flex {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.flex-350-item {
    flex-basis: 350px;
    flex-grow: 1;
}

.flex-280-item {
    flex-basis: 280px;
    flex-grow: 1;
}

.flex-450-item {
    flex-basis: 450px;
    flex-grow: 1;
}

.flex-400-item {
    flex-basis: 400px;
    flex-grow: 1;
}

.about-us {
    padding: 3rem clamp(1rem, 4vw, 8rem);
    background: hsl(0, 2%, 91%);
    border-radius: 4px;
    margin: clamp(.5rem, 4vw, 4rem);
    gap: 3rem;
}

.about-us>div {
    flex-basis: 300px;
    flex-grow: 1;
}

.about-us>img {
    width: 50%;
    flex-basis: 300px;
    flex-grow: 1;
}

.about-us h2,
.what-we-do h2,
.join h2 {
    color: hsl(217, 73%, 12%);
}

.contact-btn {
    background: hsl(121, 44%, 49%);
    padding: 1rem 1.5rem;
    font-family: "Work Sans";
    font-weight: 500;
    font-size: 1.1rem;
    color: white;
    display: block;
    margin: 1rem 0;
    width: fit-content;
    height: fit-content;
    transition: all .1s ease;
}

.contact-btn:hover {
    color: white;
    cursor: pointer;
}

.what-we-do {
    padding: 2rem clamp(1rem, 3vw, 8rem);
    margin: clamp(.5rem, 4vw, 4rem);
    background: whitesmoke;
}

.what-we-do p {
    font-size: 1rem;
    line-height: 1.5rem;
    font-family: "Work Sans";
    margin: .5rem 0;
}

.what-we-do h3 {
    margin-top: .3rem;
    font-size: 1.2rem;
    font-family: "Work Sans";
}

.what-we-do>div {
    gap: 1rem;
    margin: 2rem 0;
    justify-content: center;
}

.what-we-do>div>div {
    background: hsl(191, 100%, 43%);
    padding: 1rem;
    border-radius: 3px;
    color: white;
    max-width: 230px;
    max-height: 240px;
    text-align: center;
    overflow: hidden;
    flex-grow: 1;
    flex-basis: 200px;
}


.what-we-do>div img,
.what-we-do svg {
    border-radius: 50%;
    height: 3.8rem;
    width: 3.8rem;
    background: rgba(37, 227, 154, 0.6);
    padding: .8rem;
}

.seemore-btn {
    cursor: pointer;
    background: hsl(121, 44%, 49%);
    color: white;
    border: none;
    padding: .5rem 1rem;
    font-size: .8rem;
}

.show-card {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    background: hsl(191, 100%, 43%);
    height: fit-content;
    width: clamp(300px, 80vw, 400px);
    padding: 1rem;
    transition: all .6s ease;
    box-shadow: 0px 0px 20px hsl(217, 73%, 12%, .5);
}

.show-card p {
    font-size: .9rem;
}

.show-card button {
    color: white;
    background: hsl(217, 73%, 12%);
    position: absolute;
    font-size: 1rem;
    border-radius: 50%;
    border: none;
    height: 25px;
    width: 25px;
    top: -35px;
    right: -29px;
    cursor: pointer;
}

.hide-card {
    display: none;
}

.what-we-do .main-content {
    font-size: .9rem;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.what-we-do svg path {
    fill: white;
}

.testimony {
    color: white;
    padding: 2rem clamp(1rem, 4vw, 4rem);
    margin: 2rem clamp(1rem, 4vw, 4rem);
    background: hsl(217, 73%, 12%);
    position: relative;
    overflow: hidden;
}

.testimony .bg1 {
    position: absolute;
    max-height: 10rem;
    left: -10%;
    opacity: .1;
    transform: rotate(-90deg);
    pointer-events: none;
}

.testimony .bg2 {
    position: absolute;
    max-height: 10rem;
    bottom: -20%;
    opacity: .1;
    left: 40%;
    transform: rotate(-90deg);
    pointer-events: none;
}

.testimony .bg3 {
    position: absolute;
    max-height: 10rem;
    right: -2rem;
    top: 0;
    opacity: .1;
    pointer-events: none;
}

.testimony h2 {
    margin: 0 0 1rem 0;
}

.testimony>div {
    margin-right: clamp(1rem, 7vw, 8rem);
    margin-bottom: 2rem;
}

.testimony>div div {
    margin-top: 2rem;
}

.testimony figure img {
    width: 100%;
}

.testimony button {
    color: white;
    border: none;
    background: hsl(157, 77%, 52%);
    height: 45px;
    width: 45px;
    font-size: 18px;
}

.testimony button:nth-child(2) {
    margin-left: 2rem;
}

@media(max-width: 400px) {
    .join input {
        width: 100%;
    }

    .join button {
        border: 0px;
    }
}

@media(max-width: 900px) {
    .about-us figure img:nth-child(2) {
        width: 50%;
        transform: translate(100%, -70%);
    }
}

@media(max-width: 916px) {

    .about-us figure {
        margin-bottom: 0rem;
    }

    .about-us figure img:nth-child(2) {
        width: 50%;
        transform: translate(100%, -70%);
    }

    .join .bg1 {
        top: -50%;
    }

    .join .bg2 {
        display: none;
    }
}

@media(max-width: 600px) {
    .testimony .bg1 {
        left: -6rem;
    }

    .testimony .bg3 {
        top: 50%;
        right: -30%;
        transform: rotate(90deg);
    }
}

@media(max-width: 1100px) {
    .testimony .bg2 {
        display: none;
    }
}

@media(max-width: 300px) {
    .show-card {
        top: 60%;
    }

    .show-card button {
        top: -45px;
        right: -15px;
    }
}