.slideshow {
  margin: 0;
  overflow: hidden;
  width: 100%;
  position: relative;
  height: 550px;
}

.slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transform: translateX(50%);
  transition: all .5s ease;
}

.sliderimg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.wrapper {
  /* background: hsl(0, 0%, 0%, 0.4); */
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  animation: slide-up 1s ease .5s;
  animation-fill-mode: forwards;
  visibility: hidden;
  transition: all .4s ease;
}

.content div {
  max-width: 500px;
  margin: clamp(2rem, 10vw, 8rem) clamp(2rem, 4vw, 8rem);
  padding-top: 3%;
}

.content>* {
  color: white;
  margin-bottom: 1rem;
}

.content h1 {
  margin-top: 40%;
  font-size: 2rem;
  opacity: 0;
  animation: slide-up .5s ease .5s;
  animation-fill-mode: forwards;
  visibility: hidden;
  transition: all .4s ease;
  transform: scale(.7);
  background: hsl(121, 44%, 49%);
  line-height: 3rem;
  display: inline;
}

.content p {
  font-size: 1.3rem;
  opacity: 0;
  animation: slide-up .5s ease .5s;
  animation-fill-mode: forwards;
  visibility: hidden;
  transition: all .4s ease;
  transform: scale(.7);
}

.current {
  opacity: 1;
  transform: translateX(0);
}

.current .content {
  opacity: 1;
}

.arrow {
  border: 2px solid white;
  background-color: transparent;
  color: #fff;
  cursor: pointer;
  height: 1.1rem;
  width: 1.1rem;
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  opacity: .8;
}

.arrow:hover {
  background-color: #fff;
  color: #777;
}

.next {
  top: 40%;
  right: .5rem;
}


.prev {
  top: 40%;
  left: .5rem;
}

@keyframes slide-up {
  0% {
    visibility: visible;
    left: 13rem;
  }

  100% {
    visibility: visible;
    left: 0;
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes slide-up2 {
  0% {
    opacity: .8;
    visibility: visible;
    transform: scale(.9);
  }

  100% {
    opacity: 1;
    visibility: visible;
    opacity: 1;
    transform: scale(1);
  }
}

@media (max-width: 500px) {
  .content div {
    margin: clamp(2rem, 10vw, 8rem) .5rem clamp(2rem, 4vw, 8rem) .5rem;
  }

  .next {
    top: 90%;
    right: .5rem;
  }

  .prev {
    top: 90%;
    left: .5rem;
  }
}

@media (max-width: 730px) {
  .content {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    animation: slide-up2 1s ease .5s;
    animation-fill-mode: forwards;
    visibility: hidden;
    transition: all .4s ease;
  }
}