.modal {
    position: fixed;
    inset: 0;
    backdrop-filter: blur(1px);
    z-index: 5;
    transition: .5s ease-in-out;
}

.modal>div {
    position: relative;
    z-index: 6;
    top: 53%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: clamp(250px, 80vw, 320px);
    min-height: 400px;
    max-height: 500px;
    background: hsla(121, 44%, 49%, 1);
    padding: .7rem;
    border-radius: 2px;
}

.modal figure {
    width: 100%;
    overflow: hidden;
    max-height: 250px;
    border-radius: 2px;
}

.modal img {
    width: 100%;
}

.modal p {
    font-size: .9rem;
    line-height: 1.4rem;
    margin: 1rem 0;
    color: white;
}

.modal .text {
    overflow-y: scroll;
    padding: 0;
    height: 180px;
    margin: 1rem 0;
}

.text h3 {
    font-size: 1.2rem;

}

.text h4 {
    font-size: .9rem;
    font-weight: 400;
    margin-top: .5rem;
}

.text p {
    margin: 1rem 0;
    font-size: .9rem;
    font-weight: 400;
}

.text span {
    width: 50px;
    height: 2px;
    margin: 1rem 0;
    background: white;
    display: block;
}

.modal div::-webkit-scrollbar {
    display: none;
}

.modal button {
    color: white;
    background: hsla(217, 73%, 12%, 1);
    height: 30px;
    width: 29px;
    font-size: 1.5rem;
    border-radius: 50%;
    border: none;
    position: absolute;
    top: -10px;
    right: -10px;
    cursor: pointer;
    z-index: 2;
}

.management {
    display: flex;
    overflow-x: scroll;
    padding-right: 1rem;
    margin: 1rem 0;
    gap: 1rem;
    -ms-overflow-style: none;
    scrollbar-width: none;
    color: white;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.management::-webkit-scrollbar {
    display: none;
}

.management>div {
    min-width: 14rem;
    max-width: 14rem;
    height: 17.3rem;
    margin: 1rem 1rem 1rem 0;
    border-radius: 2px 2px 2px 40px;
    position: relative;
    overflow: hidden;
}

.management>div>div {
    position: absolute;
    bottom: 0;
    background: hsl(217, 73%, 12%);
    width: 100%;
    height: 100px;
    padding: .5rem 1rem;
}

.bg-green {
    border-bottom: 4px solid hsl(157, 77%, 52%);
    background: hsla(191, 100%, 43%, 1);
    box-shadow: 0px 10px 30px hsla(121, 44%, 49%, 0.231);
}



.management h3 {
    font-size: .9rem;
    font-weight: 500;
    margin: .7rem 0;
    text-align: left;
    text-transform: uppercase;
}