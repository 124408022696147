.blog .heading {
    padding: 2rem clamp(2rem, 7vw, 6rem);
    background: hsla(217, 73%, 12%);
    color: white;
    text-align: center;
    position: relative;
}

.blog .heading .curve1 {
    position: absolute;
    max-height: 15rem;
    max-width: 15rem;
    left: -4rem;
    top: 0;
    opacity: .1;
    transform: rotate(-90deg);
    pointer-events: none;
}

.blog .heading .curve2 {
    position: absolute;
    opacity: .1;
    max-height: 10rem;
    max-width: 20rem;
    left: 57%;
    top: -10%;
    pointer-events: none;
}

.blog .heading .curve3 {
    position: absolute;
    opacity: .1;
    max-height: 10rem;
    max-width: 20rem;
    right: 0;
    bottom: -1rem;
    transform: rotate(180deg);
    pointer-events: none;
}

.blog h1 {
    font-size: 3rem;
    margin: 2rem 0;
}

.blog h2 {
    font-size: 2rem;
}

.posts {
    padding: 3rem 1rem;
}

.news {
    padding: 2rem clamp(2rem, 4vw, 6rem);
    margin: 2rem;
    box-shadow: 10px 24px 54px rgba(0, 0, 0, 0.04);
    border-radius: 5px;
    background: hsl(0, 0%, 90%);
    overflow: hidden;
}

.blue-background {
    background: hsl(191, 100%, 43%);
    color: white;
}

.blue-background blockquote,
.blue-background p {
    color: white;
}

.news__picture {
    width: 50%;
    float: right;
    margin: 0 2rem 0 2rem;
}

.news__time {
    color: hsla(138, 48%, 59%, 1);
    margin: 0;
}


.news__blockquote p {
    line-height: 1.6rem;
    color: hsla(217, 73%, 12%, .8);
    margin: 1rem 0;
    font-size: .9rem;
}

.news__blockquote img {
    display: block;
    margin: 1rem auto;
    width: 100%;
}


.news__button {
    background: hsl(121, 44%, 49%);
    padding: 1rem 1.5rem;
    font-family: "Work Sans";
    font-weight: 500;
    font-size: 1.1rem;
    color: white;
    display: block;
    margin: 1rem 0;
    width: fit-content;
    height: fit-content;
    border: none;
}

.none {
    display: none;
}

.news__picture img {
    min-width: 100%;
}

.flex-columns {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 2rem;
    padding: 2rem clamp(2rem, 4vw, 6rem);
}

.columns {
    flex-basis: 250px;
    flex-grow: 1;
    background: hsl(0, 0%, 100%);
    box-shadow: 10px 24px 54px rgba(0, 0, 0, 0.04);
    border-radius: 20px;
    padding: .8rem;
    margin: auto;
    max-width: 300px;
}

.columns img {
    height: 200px;
    width: 100%;
    background: black;
    border-radius: 20px;
}

.columns .topic {
    font-size: 1.1rem;
    line-height: 1.6rem;
    margin: 1rem 0;
}

.columns .name {
    font-size: 1rem;
}

.columns .date {
    margin: 0;
    font-size: .9rem;
    font-weight: 500;
    color: hsl(121, 44%, 49%);
}

.columns blockquote {
    color: hsla(217, 73%, 12%, .8);
    font-size: .9rem;
    line-height: 1.4rem;
    margin: 1rem 0;
}

@media (max-width: 500px) {
    .blog .heading img {
        margin: 6rem 0 -35% 0;
    }

    .blog .heading .curve2 {
        display: none;
    }

    .news {
        margin: 8% 0 2rem 0;
    }
}

@media (max-width: 680px) {
    .news__picture {
        float: none;
        margin: 1rem 0;
        width: 100%;
    }
}