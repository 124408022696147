.contact-page {
    overflow: hidden;
}

.contact-Us {
    background: hsl(217, 73%, 12%);
    color: white;
    text-align: center;
    padding: 2rem clamp(1rem, 7vw, 10rem);
    margin-bottom: 35rem;
    position: relative;
}

.contact-Us .curve1 {
    position: absolute;
    max-height: 15rem;
    max-width: 15rem;
    left: -4rem;
    top: 0;
    opacity: .1;
    transform: rotate(-90deg);
    pointer-events: none;
}

.contact-Us .curve2 {
    position: absolute;
    opacity: .1;
    max-height: 10rem;
    max-width: 20rem;
    left: 57%;
    top: -25%;
    pointer-events: none;
}

.contact-Us .curve3 {
    position: absolute;
    opacity: .1;
    max-height: 10rem;
    max-width: 20rem;
    right: -30%;
    transform: rotate(90deg);
    pointer-events: none;
}

.contact-Us h1 {
    font-size: 3rem;
    margin: 2rem 0;
}

.contact-Us p {
    max-width: 900px;
    margin: auto;
}

.contact-Us img {
    width: 100%;
    margin: 6rem 0 -21% 0;
}

.contact-form {
    background: hsl(0, 0%, 96%);
    margin: 3rem auto -40% auto;
    padding: 1rem clamp(.5rem, 4vw, 5rem);
    text-align: left;
    box-shadow: 0px 10px 35px rgba(0, 0, 0, 0.03);
    border-radius: 8px;
    max-width: 800px;
}

.contact-form div {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    margin-top: 2rem;
}

.contact-form label {
    color: hsla(207, 19%, 44%, 1);
    font-size: 1rem;
    font-weight: 500;
    display: block;
    flex-basis: 250px;
    flex-grow: 1;
}

.message {
    margin-top: 2rem;
}

textarea {
    margin: 2rem 0;
}

textarea:focus {
    outline: none;
    border: none;
}

.contact-form input {
    margin: 1rem 0;
}

.contact-form p {
    color: hsla(207, 19%, 44%, 1);
    font-size: .9rem;
    min-width: 100%;
}

.contact-form input,
.contact-form textarea {
    border: 1px solid hsla(221, 22%, 81%, 1);
    resize: none;
    font-size: 1rem;
    font-family: "Ubuntu";
    border-radius: 4px;
    width: 100%;
    padding: .8rem 1rem;
}


.contact-btn {
    background: hsl(121, 44%, 49%);
    padding: 1rem 1.5rem;
    font-family: "Work Sans";
    font-weight: 500;
    font-size: 1.1rem;
    color: white;
    display: block;
    margin: 2rem 0 1rem 0;
    width: fit-content;
    height: fit-content;
    border: none;
}

.map {
    padding: 2rem clamp(1rem, 5vw, 5rem);
    display: block;
    margin: 0 auto;
    text-align: center;
}

.map p {
    font-size: 1.3rem;
    font-weight: 500;
    color: hsl(217, 73%, 12%);
}

.map iframe {
    width: clamp(200px, 80%, 1000px);
}


@media(max-width: 1200px) {
    .contact-form {
        margin: 4rem auto -50% auto;
    }
}

@media(max-width: 1000px) {
    .contact-form {
        margin: 4rem auto -65% auto;
    }
}

@media(max-width: 700px) {
    .contact-form {
        margin: 4rem auto -90% auto;
    }

    .contact-Us .curve2 {
        display: none;
    }
}

@media(max-width: 500px) {
    .contact-form {
        margin: 4rem auto -120% auto;
    }
}

@media(max-width: 450px) {
    .contact-form {
        margin: 4rem auto -150% auto;
    }
}

@media(max-width: 350px) {
    .contact-form {
        margin: 4rem auto -190% auto;
    }
}