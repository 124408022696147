.carrer {
    color: white;
    padding: 2rem clamp(1rem, 7vw, 10rem);
    background: hsl(217, 73%, 12%);
    text-align: center;
    position: relative;
}

.carrer h1 {
    font-size: 3rem;
    margin: 2rem auto;
}

.carrer p {
    width: clamp(100px, 100%, 900px);
    margin: auto;
}

.carrer .curve1 {
    position: absolute;
    max-height: 15rem;
    max-width: 15rem;
    left: -4rem;
    top: 0;
    opacity: .1;
    transform: rotate(-90deg);
    pointer-events: none;
}

.carrer .curve2 {
    position: absolute;
    opacity: .1;
    max-height: 10rem;
    max-width: 20rem;
    left: 57%;
    top: -10%;
    pointer-events: none;
}

.carrer .curve3 {
    position: absolute;
    opacity: .1;
    max-height: 10rem;
    max-width: 20rem;
    right: 0;
    bottom: -1rem;
    transform: rotate(180deg);
    pointer-events: none;
}

.carrer div {
    background: white;
    width: 100%;
    height: fit-content;
    border-radius: 5px;
    padding: 1rem;
    margin: 4rem 0 -30% 0;
    box-shadow: 5px 15px 45px rgba(0, 0, 0, 0.03);
    height: 500px;
}

.internship a {
    display: block;
    text-align: left;
    margin: 2rem 0;
}

.carrer h2 {
    color: hsl(217, 73%, 12%);
    text-align: left;
}




@media(max-width: 650px) {
    .carrer .curve2 {
        display: none;
    }



}