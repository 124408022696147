.intro {
    background: hsl(217, 73%, 12%);
    color: white;
    text-align: center;
    padding: 3rem clamp(1rem, 7vw, 10rem);
    margin-bottom: 2rem;
    position: relative;
    overflow: hidden;
}

.intro-bg1 {
    position: absolute;
    opacity: .1;
    max-height: 10rem;
    max-width: 20rem;
    left: 0;
    top: -1rem;
    pointer-events: none;
}

.intro-bg2 {
    position: absolute;
    opacity: .1;
    max-height: 10rem;
    max-width: 20rem;
    left: 57%;
    top: -10%;
    pointer-events: none;
}

.intro-bg3 {
    position: absolute;
    opacity: .1;
    max-height: 10rem;
    max-width: 20rem;
    right: 0;
    bottom: -1rem;
    transform: rotate(180deg);
    pointer-events: none;
}

.intro h1 {
    font-size: 3rem;
    margin: 2rem 0;
}

.intro p {
    max-width: 900px;
    margin: auto;
}

.flex {
    display: flex;
    flex-wrap: wrap;
}

.flex-350 {
    flex-basis: 400;
    flex: 1;
}

.leadership,
.sustain,
.patnership {
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;
    padding: 3rem clamp(.5rem, 4vw, 4rem);
    margin: 1rem clamp(.5rem, 4vw, 4rem);
    padding-top: 4rem;
    background: hsl(0, 0%, 97%);
}

/* .sustain p {} */

.patnership p {
    margin-top: 0;
    margin-bottom: .5rem;
}

.record {
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;
    padding: 2rem clamp(.5rem, 4vw, 4rem) 2rem clamp(.5rem, 4vw, 4rem);
    background: hsl(0, 0%, 97%);
    margin: 1rem clamp(.5rem, 4vw, 4rem);
}

.record figure img {
    max-width: 100%;
    min-height: calc(100% - 1.5rem);
    object-fit: cover;
}

.record p {
    margin-top: 0;
}

.record h2 {
    margin-top: 0;
}

.record div,
.record figure,
.leadership div,
.leadership figure,
.sustain div,
.patnership div {
    flex-basis: 350px;
    flex-grow: 1;
    color: hsl(217, 73%, 12%);
}

.leadership {
    padding: 3rem clamp(.5rem, 4vw, 4rem);
    margin: 2rem clamp(.5rem, 4vw, 4rem);
}

.leadership p {
    margin-top: 0;
    margin-bottom: .2rem;
}

.sustain-text {
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    line-clamp: 5;
    -webkit-box-orient: vertical;
}

.whitsle-text {
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 9;
    line-clamp: 9;
    -webkit-box-orient: vertical;
}

.patnership-text {
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 10;
    line-clamp: 10;
    -webkit-box-orient: vertical;
}

.full-text {
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(3px);

}

.full-text>div {
    width: clamp(200px, 100vw, 600px);
    max-height: 400px;
    top: 40%;
    bottom: 20%;
    overflow-y: scroll;
    background: hsl(0, 0%, 96%);
    padding: 1rem;
    margin: 0 1rem;
    border-radius: 5px;
}

.quality-text {
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 9;
    line-clamp: 5;
    -webkit-box-orient: vertical;
}


.record button {
    background: hsl(121, 44%, 49%);
    padding: 1rem 1.5rem;
    font-family: "Work Sans";
    font-weight: 500;
    font-size: 1.1rem;
    color: white;
    display: block;
    margin: 1rem 0;
    width: fit-content;
    height: fit-content;
    border: none;
}

.dynamics {
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;
    padding: 3rem clamp(.5rem, 4vw, 4rem);
    margin: 2rem clamp(.5rem, 4vw, 4rem);
    color: white;
    background: hsla(191, 100%, 43%, 1);
}

.dynamics div,
.dynamics figure {
    flex-basis: 350px;
    flex-grow: 1;
}

.dynamics figure img {
    width: 100%;
    min-height: calc(100% - 2rem);
    object-fit: cover;
}

.mail {
    text-align: center;
    color: white;
    background: hsl(217, 73%, 12%);
}

.mail .email {
    padding: .8rem;
    background: white;
    width: fit-content;
    height: fit-content;
    margin: 1rem auto;
}

.email button,
.email input {
    font-size: 1rem;
    font-family: "Work Sans";
    border: none;
    height: fit-content;
    background: white;
    height: fit-content;
    padding: 0 1rem;
    margin: auto;
}

.email button {
    border-left: 1px solid hsl(217, 73%, 12%, 0.5);
    color: hsl(121, 44%, 49%);
    cursor: pointer;
}

.benefits {
    display: flex;
    flex-wrap: wrap;
    padding: 3rem clamp(.5rem, 4vw, 4rem);
    margin: 1rem clamp(.5rem, 4vw, 4rem);
    color: hsl(217, 73%, 12%);
    gap: 3rem;
    background: whitesmoke;
}

.benefits p {
    margin-top: 0;
}

.benefits figure img {
    width: 100%;
    min-height: calc(100% - 2rem);
    object-fit: cover;
}

.benefits figure,
.benefits div {
    flex-basis: 350px;
    flex-grow: 1;
}

.strategy,
.compliance {
    background: hsla(191, 100%, 43%, 1);
    color: white;
    margin: 2rem 0;
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;
    padding: 3rem clamp(1rem, 4vw, 4rem);
    margin: 1rem clamp(1rem, 4vw, 4rem);
}

.strategy div,
.compliance div {
    flex-basis: 350px;
    flex-grow: 1;
}

.strategy img,
.compliance img {
    flex-basis: 350px;
    flex-grow: 1;
    width: 50%;
    object-fit: cover;
    margin-top: 0;
}

.compliance>img {
    padding: 2rem;
    background: #C1E6EE;
    object-fit: contain;
}


.team {
    background: hsla(191, 100%, 43%, 1);
    color: white;
    padding: 2rem clamp(.5rem, 4vw, 4rem);
    margin: 2rem clamp(.5rem, 4vw, 4rem);
    overflow-x: hidden;
}

.members h3 {
    font-size: 1rem;
    font-weight: 400;
    cursor: pointer;
    flex-grow: 1;
    /* flex-basis: 100px; */
    padding: 1rem .7rem;
    text-align: center;
    min-width: fit-content;
    max-width: fit-content;
}

.members {
    background: hsla(0, 0%, 100%, 0.05);
    gap: .5rem;
    display: flex;
    flex-wrap: wrap;
    height: fit-content;
    width: fit-content;
    max-width: 550px;
    overflow-x: scroll;
}

.brains {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    gap: 2rem;
}

.brains p {
    margin-bottom: 0;
    max-width: 430px;
}

.brains div {
    flex-basis: 480px;
    flex-grow: 1;
    max-width: fit-content;
}

.staffs {
    display: flex;
    overflow-x: scroll;
    color: white;
    gap: 1rem;
    -ms-overflow-style: none;
    scrollbar-width: none;
    margin: 1rem 0;
}

.staffs::-webkit-scrollbar {
    display: none;
}

.hover-bg {
    background: hsl(121, 44%, 49%);
    padding: 1rem;
    border-radius: 2px 2px 2px 40px;
    height: fit-content;
    position: absolute;
}

.block {
    display: block;
}

.staffs figure {
    overflow: hidden;
    width: 100%;
}

.staffs figure img {
    width: 100%;
}





.staffs h4 {
    font-size: .9rem;
    font-weight: 400;
    color: hsl(121, 44%, 49%);
    margin: .5rem 0;
}

.quality,
.whistle {
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;
    padding: 3rem clamp(.5rem, 4vw, 4rem);
    margin: 1rem clamp(.5rem, 4vw, 4rem);
    background: whitesmoke;
}

.quality {
    background: hsla(191, 100%, 43%, 1);
}

.quality p,
.quality h2 {
    color: white;
}

.quality h2 {
    margin-top: -.5rem;
}

.quality p {
    margin: 0;
}

.quality div,
.quality figure,
.whistle div,
.whistle figure {
    flex-basis: 350px;
    flex-grow: 1;
}

.quality div,
.whistle div {
    color: hsl(217, 73%, 12%);
}

.quality>figure {
    margin-top: -2rem;
}

@media(max-width: 900px) {
    .record p {
        margin-top: 0;
    }
}

@media(min-width: 1200px) {
    .record {
        margin-top: 3rem;
    }
}

@media(max-width: 450px) {
    .record {
        margin-top: 4rem;
    }

    .mail .email {
        background: transparent;
    }

    .email button {
        border: 0px;
        margin: 1rem 0;
    }

    .email button,
    .email input {
        padding: .8rem 1rem;
        border-radius: 5px;
    }

    .intro-bg2 {
        display: none;
    }
}