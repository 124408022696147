@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&family=Ubuntu:wght@300;400;500;700&family=Work+Sans:wght@300;400;500;600&display=swap');

body {
  margin: 0;
  font-family: "Ubuntu", Arial, 'Segoe UI', 'Roboto', 'Oxygen', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth;
}

body {
  max-width: 2700px;
  margin: 0 auto;
  box-shadow: 5px 15px 45px rgba(0, 0, 0, 0.03);
  -ms-overflow-style: none;
  scrollbar-width: none;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

*::-webkit-scrollbar {
  display: none;
}

body::-webkit-scrollbar {
  display: none;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

p {
  font-size: .95rem;
  line-height: 1.8rem;
  font-weight: 400;
  margin: 1.5rem 0;
}

h2 {
  font-size: 2rem;
  margin: 0 0 1rem 0;
}

input:focus,
button:focus {
  border: none;
  outline: none
}

.ecg {
  background: url("./images/ecg.svg");
  background-size: 1500px;
  background-repeat: repeat-x;
  position: absolute;
  height: 500px;
  width: 100vw;
  top: 20%;
  z-index: 1;
  opacity: .7;
}

@media(max-width: 600px) {
  .ecg {
    background-size: 500px;
  }
}