.board-carousel {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    margin-top: 3rem;
}

.animate {
    opacity: 0;
    transition-duration: .4s ease;
}

.animate.true {
    opacity: 1;
    transition-duration: .4s;
    transform: scale(1);
}

.staff {
    min-width: 14rem;
    max-width: 17rem;
    height: 21.3rem;
    /* margin: 1rem 0; */
    border-radius: 2px 2px 2px 40px;
    position: relative;
    overflow: hidden;
}

.staff div {
    position: absolute;
    bottom: 0;
    background: hsl(217, 73%, 12%);
    width: 100%;
    height: 100px;
    padding: .5rem 1rem;
}

.staff h3 {
    margin: .7rem 0;
    text-align: left;
    font-size: .9rem;
    font-weight: 500;
    text-transform: uppercase;
}

.staff h4 {
    margin: .5rem 0;
    text-align: left;
    font-size: .9rem;
    font-weight: 400;
    color: hsl(121, 44%, 49%);
}

.staff p {
    margin: 1rem 0;
    font-size: .9rem;
    font-weight: 300;
    opacity: 0;
    pointer-events: none;
}

.staff figure {
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.staff figure img {
    width: 100%;
    min-height: 80%;
    object-fit: cover;
}

.scroller {
    margin-left: auto;
    width: fit-content;

}

.scroller button {
    height: 2.5rem;
    max-height: 40px;
    width: 2.5rem;
    margin-left: .7rem;
    font-size: 1.1rem;
    font-family: "poppins";
    font-weight: 600;
    border: none;
    background: white;
    transition: all .2s ease;
    cursor: pointer;
}

.scroller button:active {
    background: hsl(121, 44%, 49%);
}